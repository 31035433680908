<template>
  <div id="content" :style="backgroundDiv">
    <NavbarCommon :title="title"></NavbarCommon>
    <van-notice-bar
      left-icon="volume-o"
      text="若长时间加载班级，请切换界面刷新^_^"
    />
    <ClassesList v-if="this.classeslist"></ClassesList>
    <!-- <ClassesList v-if="this.$store.state.study.classeslist"></ClassesList> -->
    <van-loading v-else size="6.667vw" vertical>班级列表加载中...</van-loading>
  </div>
</template>

<script>
import NavbarCommon from '@/components/common/NavbarCommon'
import ClassesList from '@/views/buddhism/classes/ClassesList'
export default {
  components: {
    NavbarCommon, ClassesList
  },
  data () {
    return {
      title: '报名学习',
      //   classeslistok: false,
      backgroundDiv: {
        backgroundImage: 'url(' + require('@/assets/classid.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      }
    }
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', false)
    this.$store.dispatch('getClassesDataAction', {
      getModel: 'ONLY'
    })
  },
  mounted () {
    // if (this.$store.state.clockin.clockinList.length === 0) {
    // this.$store.dispatch('getSystemInfoAction', {
    //   uid: localStorage.getItem('uid'),
    //   classid: localStorage.getItem('classid')
    // })
    // }
    // if (this.$store.state.study.classeslist === undefined) {
    // this.$store.dispatch('getClassesDataAction', {
    //   getModel: 'ONLY'
    // })
    // console.log(this.classeslistok)
    // }
  },
  watch: {
    // classeslistok () {
    //   if (this.classid > 0) {
    //     this.$route.push('/buddhism')
    //   }
    // }
    // classeslist () {
    //   this.classlistupdate = true
    //   console.log(this.classeslist, 'classeslist watch')
    //   this.$forceUpdate()
    // }
  },
  computed: {
    classeslistok () {
      return this.$store.state.classeslistok
    },
    classeslist () {
      //   const clslist = localStorage.getItem('classeslist')
      //   //   localStorage.getItem('classeslist')
      //   //   let clslist = this.$store.study.classeslist
      //   console.log(clslist, '++++')
      //   if (clslist === null) {
      //     return false
      //   } else {
      return this.$store.state.study.classeslist
      //   }
    }
  },
  methods: {
    // toClockin (id, name) {
    //   localStorage.setItem('clockid', id)
    //   localStorage.setItem('clockinname', name)
    //   this.$router.push('/clockin')
    // }

  }
}
</script>

<style lang='scss' scoped>
#content {
  text-align: center;
  margin: auto;
  width: 100%;
  height: 188.889vw;
}

.title {
  color: white;
  background-color: rgb(70, 27, 27);
  line-height: 5.556vw;
  font-size: 4.167vw;
  height: 9.722vw;
  margin: 0 auto;
  padding-top: 4.167vw;
}
.inline {
  display: flex;
  justify-content: space-between;
}
ul,
li {
  padding: 0vw;
  margin: 0vw;
  list-style: none;
}
ul {
  background-color: rgb(241, 231, 231);
  text-align: left;
  li {
    // height: 5.556vw;
    padding: 1.389vw;
    color: rgb(37, 26, 26);
    border-bottom: 0.278vw solid rgb(14, 12, 12);
    font-size: 3.611vw;
    line-height: 5.556vw;
  }
}
.flex-container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
}
.flex-item {
  width: 50%;
  text-align: center;
}
.clockin {
  color: rgb(196, 145, 111);
  padding: 0.556vw;
  margin-right: 4.167vw;
  margin-top: 1.389vw;
}
.noclockin {
  color: red;
  font-weight: bold;
  font-size: 3.333vw;
  margin-right: 2.778vw;
  margin-top: 1.389vw;
}
.hasClockin {
  color: green;
}
.unClockin {
  color: red;
}
.marginbottom {
  height: 14.444vw;
}
</style>
